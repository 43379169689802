var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-code",
    {
      attrs: { title: "Calendar Width" },
      scopedSlots: _vm._u([
        {
          key: "code",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.codeWidth) + " ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c("b-card-text", [
        _c("span", [_vm._v("To change the width, set the ")]),
        _c("code", [_vm._v("width")]),
        _c("span", [_vm._v(" prop to any valid CSS width (including units).")])
      ]),
      _c("b-card-text", [
        _c("span", [
          _vm._v(
            "Optionally, make the calendar full width by setting the prop "
          )
        ]),
        _c("code", [_vm._v("block")]),
        _c("span", [
          _vm._v(
            ", which will make it expand to fit the width of the parent element. The "
          )
        ]),
        _c("code", [_vm._v("width")]),
        _c("span", [_vm._v(" prop has no effect when ")]),
        _c("code", [_vm._v("block")]),
        _c("span", [_vm._v(" is set.")])
      ]),
      _c("b-calendar", { attrs: { block: "", locale: "en-US" } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }