var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-code",
    {
      attrs: { title: "Calendar Border & padding" },
      scopedSlots: _vm._u([
        {
          key: "code",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.codeBorderPadding) + " ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c("b-card-text", [
        _vm._v(
          " Fancy a calendar with a border with padding? Use Bootstrap's border and padding utility classes to add borders and padding: "
        )
      ]),
      _c(
        "div",
        { staticClass: "d-flex align-items-center justify-content-center" },
        [
          _c("b-calendar", {
            staticClass: "border rounded p-2",
            attrs: { locale: "en" }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }