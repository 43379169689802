var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-code",
    {
      attrs: { title: "Calendar" },
      scopedSlots: _vm._u([
        {
          key: "code",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.codeBasic) + " ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c("b-card-text", { staticClass: "mb-0" }, [
        _c("span", [_vm._v("BootstrapVue's custom ")]),
        _c("code", [_vm._v("<b-calendar>")]),
        _c("span", [
          _vm._v(
            " component generates a WAI-ARIA compliant calendar style date selection widget, which can be used to control other components, or can be used to create customized date picker inputs. "
          )
        ])
      ]),
      _c(
        "b-row",
        { staticClass: "text-center" },
        [
          _c(
            "b-col",
            { staticClass: "mt-1", attrs: { md: "6" } },
            [
              _c("b-calendar", {
                attrs: { locale: "en-US" },
                on: { context: _vm.onContext },
                model: {
                  value: _vm.value,
                  callback: function($$v) {
                    _vm.value = $$v
                  },
                  expression: "value"
                }
              })
            ],
            1
          ),
          _c("b-col", { attrs: { md: "6" } }, [
            _c(
              "div",
              { staticClass: "mt-1" },
              [
                _c(
                  "prism",
                  {
                    staticClass: "rounded mb-0",
                    attrs: { language: "javascript" }
                  },
                  [_vm._v(" Context: " + _vm._s(_vm.context) + " ")]
                )
              ],
              1
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }