var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-row",
    [
      _c("b-col", { attrs: { cols: "12" } }, [_c("calendar-basic")], 1),
      _c(
        "b-col",
        { attrs: { md: "6" } },
        [_c("calendar-disable-readonly-states")],
        1
      ),
      _c("b-col", { attrs: { md: "6" } }, [_c("calendar-disable-date")], 1),
      _c("b-col", { attrs: { md: "6" } }, [_c("calendar-min-max-range")], 1),
      _c("b-col", { attrs: { md: "6" } }, [_c("calendar-variant")], 1),
      _c("b-col", { attrs: { md: "6" } }, [_c("calendar-width")], 1),
      _c("b-col", { attrs: { md: "6" } }, [_c("calendar-border-padding")], 1),
      _c("b-col", { attrs: { cols: "12" } }, [_c("calendar-string-format")], 1),
      _c("b-col", { attrs: { md: "6" } }, [_c("calendar-slot")], 1),
      _c("b-col", { attrs: { md: "6" } }, [_c("calendar-custom-class")], 1),
      _c("b-col", [_c("calendar-internationalization")], 1)
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }