var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-code",
    {
      attrs: { title: "Internationalization" },
      scopedSlots: _vm._u([
        {
          key: "code",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.codeInternationalization) + " ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c("b-card-text", [
        _c("span", [_vm._v("By default ")]),
        _c("code", [_vm._v("<b-calendar>")]),
        _c("span", [
          _vm._v(
            " will use the browser's default locale, but you can specify the locale (or locales) to use via the "
          )
        ]),
        _c("code", [_vm._v("locale")]),
        _c("span", [
          _vm._v(
            " prop. The prop accepts either a single locale string, or an array of locale strings (listed in order of preferred locale). "
          )
        ])
      ]),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { cols: "12" } },
            [
              _c("label", { attrs: { for: "example-locales" } }, [
                _vm._v("Locale:")
              ]),
              _c("b-form-select", {
                attrs: { id: "example-locales", options: _vm.locales },
                model: {
                  value: _vm.locale,
                  callback: function($$v) {
                    _vm.locale = $$v
                  },
                  expression: "locale"
                }
              }),
              _c(
                "label",
                { staticClass: "mt-2", attrs: { for: "example-weekdays" } },
                [_vm._v("Start weekday:")]
              ),
              _c("b-form-select", {
                attrs: { id: "example-weekdays", options: _vm.weekdays },
                model: {
                  value: _vm.weekday,
                  callback: function($$v) {
                    _vm.weekday = $$v
                  },
                  expression: "weekday"
                }
              }),
              _c("div", { staticClass: "demo-inline-spacing" }, [
                _c(
                  "div",
                  { staticClass: "d-flex align-items-center" },
                  [
                    _c(
                      "b-form-checkbox",
                      {
                        attrs: { switch: "", inline: "" },
                        model: {
                          value: _vm.showDecadeNav,
                          callback: function($$v) {
                            _vm.showDecadeNav = $$v
                          },
                          expression: "showDecadeNav"
                        }
                      },
                      [_vm._v(" Show decade navigation buttons ")]
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "d-flex align-items-center" },
                  [
                    _c(
                      "b-form-checkbox",
                      {
                        attrs: { switch: "", inline: "" },
                        model: {
                          value: _vm.hideHeader,
                          callback: function($$v) {
                            _vm.hideHeader = $$v
                          },
                          expression: "hideHeader"
                        }
                      },
                      [_vm._v(" Hide the date header ")]
                    )
                  ],
                  1
                )
              ])
            ],
            1
          ),
          _c(
            "b-col",
            { staticClass: "text-center", attrs: { md: "6" } },
            [
              _c(
                "b-calendar",
                _vm._b(
                  {
                    staticClass: "mt-1",
                    attrs: {
                      locale: _vm.locale,
                      "start-weekday": _vm.weekday,
                      "hide-header": _vm.hideHeader,
                      "show-decade-nav": _vm.showDecadeNav
                    },
                    on: { context: _vm.onContext },
                    model: {
                      value: _vm.value,
                      callback: function($$v) {
                        _vm.value = $$v
                      },
                      expression: "value"
                    }
                  },
                  "b-calendar",
                  _vm.labels[_vm.locale] || {},
                  false
                )
              )
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { md: "6" } },
            [
              _c(
                "prism",
                {
                  staticClass: "rounded mt-1 mb-0",
                  attrs: { language: "javascript" }
                },
                [_vm._v(" Context: " + _vm._s(_vm.context) + " ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }